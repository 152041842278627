import Footer from '../layouts/generic/footer';
import Header from '../layouts/generic/header';
import { PolicyHeaderImage } from '../lib/images';
import { ROUTES } from '../lib/route-links';

const PrivacyPolicy = () => {   

    return (
        <div className="w-full justify-start items-center bg-white">
            <main className="w-full block">
               <Header link={ROUTES.SIGNUP} buttonText='Get Started'/>
               <section className='w-full flex min-h-[300px] bg-[#FFFFFF] bg-cover bg-no-repeat bg-[center_center]' style={{ backgroundImage: `url(${PolicyHeaderImage})` }}>
                    <div className='w-full flex items-center px-20 max-sm:px-0 max-sm:justify-center'> 
                      <h1 className='text-[#FFFFFF] font-bold text-left text-[35px] max-sm:text-[30px]'>Privacy Policy</h1>
                    </div>
                </section> 
                <section className='relative min-h-[450px] max-sm:min-h-[650px] bg-[#FFFFFF] py-10 px-20 max-sm:px-10'>
                    <div className='flex space-x-2 justify-center'>
                      <h4 className='text-[35px] font-semibold text-[#2F3934]'>Our <span className='text-[#EF7822]'>Privacy Policy</span></h4>
                    </div>
                    <div className='w-full py-10 px-20 max-sm:px-5 text-left'>
                      <p className='text-[#000000] text-[14px] mb-5'>This privacy policy (“Policy”) describes how Early Bird Crystal Technologies (“us,” “we,” or “our”) collects, uses, and shares information about you through our e-commerce delivering platform (“APPA”).</p>
                        <p className='text-[#000000] text-[14px] font-semibold mt-3'>Information We Collect</p>
                        <span className='text-[#000000] text-[14px]'>We collect information you provide directly to us when you use the Service, including your name, email address, phone number, and payment information. We also collect information about your use of the Service, including your delivery history, and device information.</span>
                        <p className='text-[#000000] text-[14px] font-semibold mt-3'>Use of Information</p>
                        <span className='text-[#000000] text-[14px]'>We use the information we collect to provide and improve the Service, to communicate with you about your account, to fulfill your delivery orders, to process payments, and to detect and prevent fraud.
We may also use your information to send you promotional communications about APPA, or third-party products and services we think may be of interest to you. You may opt-out of receiving promotional communications at any time.</span>
                        <p className='text-[#000000] text-[14px] font-semibold mt-3'>Sharing of Information</p>
                        <span className='text-[#000000] text-[14px]'>We may share your information with third-party service providers that help us operate and improve the Service, including payment processors, analytics providers, and delivery drivers.
We may also share your information in response to a legal request, if we believe it is necessary to protect the rights, property, or safety of APPA, our users, or others, or in connection with a business transaction.</span>
                        <p className='text-[#000000] text-[14px] font-semibold mt-3'>Security</p>
                        <span className='text-[#000000] text-[14px]'>We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no security measure is completely secure, and we cannot guarantee the security of your information.</span>
                        <p className='text-[#000000] text-[14px] font-semibold mt-3'>Your Choices</p>
                        <span className='text-[#000000] text-[14px]'>You may modify your account information or delete your account at any time by contacting us at [insert contact information]. You may also opt-out of receiving promotional communications by following the instructions in the communication.</span>
    <p className='text-[#000000] text-[14px] font-semibold mt-3'>Children’s Privacy</p>
                        <span className='text-[#000000] text-[14px]'>The Service is not intended for use by children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. If you are a parent or legal guardian and believe that your child has provided us with personal information, please contact us at support@appastore.co to have their information removed.</span>
                        <p className='text-[#000000] text-[14px] font-semibold mt-3'>Changes to this Policy</p>
                        <span className='text-[#000000] text-[14px]'>We may update this Policy from time to time. If we make material changes, we will notify you by email or by posting a notice on the Service prior to the change becoming effective. Your continued use of the Service after the effective date of the updated Policy will constitute your acceptance of the changes.</span>
                        <p className='text-[#000000] text-[14px] font-semibold mt-3'>Contact Us</p>
                        <span className='text-[#000000] text-[14px]'>If you have any questions about this Policy or our practices, or if you would like to exercise your rights under applicable law, please contact us at support@appastore.co.</span>
                    </div>
                </section>
            </main>
            <Footer type={1} />
        </div>
    );
};

export default PrivacyPolicy;